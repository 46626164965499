#birthOfTheKingImg {
    content: url('../images/birth of the king.jpg');
}

#bedtimeHymnsPeace {
    content: url('../images/Bedtime-Hymns_300.jpg');
}

#longingForHome {
    content: url('../images/longing-for-home.jpg');
}

#content {
    text-align: center;
}

#projects {
    text-align: center;
}

#projects a {
    margin-left: 15px;
    margin-right: 15px;
    text-decoration: none;
}

/*@media only screen and (min-width: 600px) {*/
/*    #birthOfTheKingDiv {*/
/*        text-align: right;*/
/*    }*/

/*    #bedtimeHymnsPeaceDiv {*/
/*        text-align: left;*/
/*    }*/
/*}*/
