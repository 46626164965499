html {
    height: 100%;
}

body {
    height: 100%;
    margin: 0;
    padding: 0;
    min-width: 300px;
}

.wrapper {
    position: relative;
    min-height: 100%;
}

body > div > footer {
    clear: both;
    height: 25px;
}

body > div > footer > div {
    position: absolute;
    bottom: 0;
    left: 3px;
}

h1 {
    margin-top: 0;
}

.isDisabled {
    color: currentColor;
    cursor: not-allowed;
    opacity: 0.5;
    text-decoration: none;
}
